
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import RaporKosulPicker from "@/components/pickers/RaporKosulPicker.vue";
import {RaporKosulEntity} from "@/entity/RaporKosulEntity";
import {RaporAlanEntity} from "@/entity/RaporAlanEntity";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import TebligatDurumuPicker from "@/components/pickers/TebligatDurumuPicker.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import TakipAltTipiPicker from "@/components/pickers/TakipAltTipiPicker.vue";
import TakipYoluPicker from "@/components/pickers/TakipYoluPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import MasrafCesidiPicker from "@/components/pickers/MasrafCesidiPicker.vue";

@Component({
  components: {
    RaporKosulPicker,
    VergiNumarasi,
    IcraDairesiPicker,
    TelefonNumarasi,
    DosyaEsasNumarasi,
    Dates,
    TebligatDurumuPicker,
    TakipTipiPicker,
    TakipAltTipiPicker,
    TakipYoluPicker,
    Tutar,
    MasrafCesidiPicker
  }
})
export default class RaporQueryBuilderSimple extends Mixins(ObjectInputMixin) {
  @Prop({}) raporAlanlari!: Array<RaporAlanEntity>;
  localValue: RaporKosulEntity = new RaporKosulEntity();

  get kosulInput() {
    if (this.raporAlanlari && this.localValue.rapor_alan_id) {
      return this.raporAlanlari.find(v => v.id == this.localValue.rapor_alan_id)?.kosul_input ?? 'v-text-field';
    } else
      return 'v-text-field';
  }
}
