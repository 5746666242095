
import {Component, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import RaporQueryBuilderGroup from "@/components/inputs/RaporQueryBuilderGroup.vue";
import {RaporAlanEntity} from "@/entity/RaporAlanEntity";
import RaporQueryBuilderSimple from "@/components/inputs/RaporQueryBuilderSimple.vue";
import {RaporKosulEntity} from "@/entity/RaporKosulEntity";
import RaporKosulGrupEntity from "@/entity/RaporKosulGrupEntity";

@Component({
  components: {RaporQueryBuilderSimple, RaporQueryBuilderGroup}
})
export default class RaporQueryBuilder extends Mixins(ObjectInputMixin) {
  localValue: RaporKosulGrupEntity=new RaporKosulGrupEntity();
  rapor_alanlari_items: Array<RaporAlanEntity> = [];
  temp_kosul: RaporKosulEntity = new RaporKosulEntity();

  mounted() {
    this.load();
    if(!this.localValue.kosullar)
      this.localValue.kosullar=[];
  }

  load() {
    this.$http.get('/api/v1/rapor-alan').then(responce => {
      this.rapor_alanlari_items = responce.data;
    })
  }

  kosulEkle() {
    if (this.temp_kosul.rapor_alan_id) {
      this.localValue.kosullar.push(this.temp_kosul);
      this.temp_kosul = new RaporKosulEntity();
    }
  }

  kosulGrubuEkle() {
    this.localValue.kosullar.push({ve_mi: true, kosullar: []});
    this.input();
  }

  kosulSil(index: number) {
    this.localValue.kosullar.splice(index, 1);
    this.input();
  }
}
