
import {Component, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import Draggable from "vuedraggable";
import {AbstractFormWizard} from "@/abstract_classes/AbstractFormWizard";
import {RaporEntity} from "@/entity/RaporEntity";
import RaporKosulPicker from "@/components/pickers/RaporKosulPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {RaporAlanEntity} from "@/entity/RaporAlanEntity";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import IcraDairesiPicker from "@/components/pickers/IcraDairesiPicker.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import DosyaEsasNumarasi from "@/components/inputs/DosyaEsasNumarasi.vue";
import Dates from "@/components/inputs/Dates.vue";
import TebligatDurumuPicker from "@/components/pickers/TebligatDurumuPicker.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import TakipAltTipiPicker from "@/components/pickers/TakipAltTipiPicker.vue";
import TakipYoluPicker from "@/components/pickers/TakipYoluPicker.vue";
import RaporQueryBuilder from "@/components/inputs/RaporQueryBuilder.vue";

@Component({
  components: {
    RaporQueryBuilder,
    TakipYoluPicker,
    TakipAltTipiPicker,
    TakipTipiPicker,
    TebligatDurumuPicker,
    Dates,
    DosyaEsasNumarasi,
    TelefonNumarasi,
    RaporKosulPicker,
    DeleteDialog,
    Draggable,
    Tutar,
    VergiNumarasi,
    IcraDairesiPicker
  }
})

export default class RaporForm extends AbstractFormWizard {
  localValue!: RaporEntity;
  activeStep: number = 1;
  rapor_alanlari_items: Array<RaporAlanEntity> = [];
  ready = false;
  onFly = false;

  stepperHeaders = [
    "Rapor Alanları",
    "Sıralama",
    "Rapor Koşulları",
    "Özet ve Kaydetme"
  ];

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  onClose() {
    this.$emit("onClose");

  }

  @Watch('localValue.alanlar')
  onAlanlarChange() {
    if (this.localValue.alanlar && this.ready) {
      this.reformatingLocalValue();
    }
  }

  created() {
    this.ready = false;
    this.load();
  }

  reformatingLocalValue() {
    let value = new RaporEntity();
    value.id = this.localValue.id;
    value.isim = this.localValue.isim;
    value.rapor_alanlari = [];
    this.localValue.alanlar.forEach((alan: any) => {
      value.rapor_alanlari.push(alan.rapor_alan_id);
    })
    value.rapor_secilen_kosullar = this.localValue.rapor_secilen_kosullar;
    this.localValue = value;
    this.input();
  }

  save() {
    this.onFly = true
    if (this.localValue.id) {
      this.$http.put('/api/v1/rapor/' + this.localValue.id, this.localValue).then(() => {
        this.activeStep = 1;
        this.$emit('onSuccess');
      }).finally(() => {
        this.onFly = false;
      });
    } else {
      this.$http.post('/api/v1/rapor', this.localValue).then(() => {
        this.resetValue();
        this.$emit('onSuccess');
        this.localValue.rapor_alanlari = [1];
      }).finally(() => {
        this.onFly = false;
      });
    }
  }

  load() {
    this.$http.get('/api/v1/rapor-alan').then(responce => {
      this.rapor_alanlari_items = responce.data;
      if (this.localValue.id) {
        this.reformatingLocalValue();
        this.ready = true;
      }
    })
  }

  remove(item: any) {
    this.localValue.rapor_alanlari.splice(this.localValue.rapor_alanlari.indexOf(item.id), 1)
  }

  nextStepRules() {
    return true;
  }

  previousStepRules() {
    return true;
  }

  async close() {
    await this.resetValue();
    this.$emit('onClose');
  }

  async resetValue() {
    this.activeStep = 1;
    if (!this.localValue.id) {
      this.localValue = new RaporEntity();
      this.localValue.rapor_secilen_kosullar.kosullar = [];
      this.input();
    }
  }
}
