
import {Component, Mixins, Prop} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {RaporAlanEntity} from "@/entity/RaporAlanEntity";
import RaporQueryBuilderSimple from "@/components/inputs/RaporQueryBuilderSimple.vue";
import {RaporKosulEntity} from "@/entity/RaporKosulEntity";

@Component({
  components: {RaporQueryBuilderSimple}
})
export default class RaporQueryBuilderGroup extends Mixins(ObjectInputMixin) {
  @Prop({}) raporAlanlari!: Array<RaporAlanEntity>;
  temp_kosul: RaporKosulEntity = new RaporKosulEntity();

  kosulEkle() {
    if (this.temp_kosul.rapor_alan_id) {
      this.localValue.kosullar.push(this.temp_kosul);
      this.temp_kosul = new RaporKosulEntity();
    }
  }

  kosulGrubuEkle() {
    this.localValue.kosullar.push({ve_mi: true, kosullar: []});
    this.input();
  }

  kosulSil(index: number) {
    this.localValue.kosullar.splice(index, 1);
    this.input();
  }
}
