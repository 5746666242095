

import {Component, Mixins, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import RaporForm from "@/components/forms/RaporForm.vue";
import {RaporEntity} from "@/entity/RaporEntity";
import {ProgramPaths} from "@/enum/ProgramPaths";
import SayfaBilgiInfo from "@/components/SayfaBilgiInfo.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, SayfaBilgiInfo, FormDialog, DeleteDialog, RaporForm}
})

export default class RaporList extends Mixins(SimpleInputMixin) {
  localValue!: Array<RaporEntity>;
  searchText: string = "";
  search: string = "";
  allCheckbox=false
  buroCheckbox: boolean[] = [];
  programPaths = ProgramPaths;


  headers = [
    /*{text: '', align: 'start', sortable: false, value: 'checkbox' },*/
    {text: "Rapor Adı", align: "start", sortable: true, value: "isim"},
    {text: "Tarih", sortable: true, value: "created_at"},
    {text: "İşlemler", value: "actions", width: 150, align: "end", sortable: false}
  ];
  oncekiSayfa(){
    this.$router.push(this.programPaths.ofispro)
  }

  @Watch('allCheckbox')
  AllSelectCheckbox(){
    if (this.allCheckbox){
      this.buroCheckbox = this.localValue.map(item => true);
    }else{
      this.buroCheckbox = this.localValue.map(item => false);
    }
  }

  selectedDelete(){
    const selectedItems = this.localValue.filter((item, index) => {
      return this.buroCheckbox[index];
    });


    const selectedIds = selectedItems.map(item => item.id);

    this.$http.delete('/api/v1/rapor/' ,{
      params: {
        ids: [selectedIds]
      }
    } ).then(this.load)
  }
  @Watch("searchText")
  onSearchTextChange() {
    this.load()
  }

  mounted() {
    if (!this.value) {
      this.localValue = []
    }
    this.load();
  }

  load() {
    let action = "/api/v1/rapor"
    if (this.searchText && this.searchText != "") {
      action = "/api/v1/rapor/filter?isim=" + this.searchText;
    }
    this.$http.get(action).then(response => {
      this.localValue = response.data;
    });
  }

  detay(id: number) {
    this.$router.push(ProgramPaths.ofispro + '/rapor/' + id);
  }
}
