
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: {DatabasePicker}
})

export default class RaporKosulPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() required!: boolean;
  @Prop() returnId!: boolean;
  @Prop() raporAlanId!: string;
  @Prop() hideDetails!: string | boolean;

  get isHideDetails() {
    return this.hideDetails || this.hideDetails === "";
  }

  get action() {
    if (this.raporAlanId)
      return '/api/v1/rapor/' + this.raporAlanId + '/kosul-operator';
    else {
      return '/api/v1/kosul-operator'
    }
  }

  onItemsChange(v: any) {
    this.$emit('kosullar', v);
    this.input();
  }
}
