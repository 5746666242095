
import {Component, Vue} from "vue-property-decorator";
import RaporList from "@/components/lists/RaporList.vue";

@Component({
  components: {RaporList}
})
export default class RaporListView extends Vue {

}
